body {
    font-family: 'Segoe UI Regular';
    color: #444444;
}

a {
    color: #ff724c;
}

figure {
    margin: 0;
}

a:hover {
    color: #ff724c;
}

input:focus {
    outline: none !important;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Segoe UI Bold';
}

.container {
    max-width: 580px;
}

header.header {
    margin-top: 20px;
}

.login-screen .main-content h2 {
    color: #000;
}

.login-screen .main-content {
    margin-top: 25px;
}

.login-screen .main-content .login-sec {
    margin-top: 50px;
}

.login-screen .main-content .login-sec label {
    margin-bottom: 0;
    color: #aeaeae;
}

.login-screen .main-content .login-sec input {
    width: 100%;
    padding: 10px 0;
    border: 0;
    border-bottom: 1px solid #aaa;
    color: #aeaeae;
}

.login-screen .main-content .login-sec .input__group {
    margin-top: 20px;
    position: relative;
}

.login-screen .main-content .login-sec .icofont-eye-alt {
    position: absolute;
    right: 0;
    top: 16px;
}

.login-screen .main-content .login-sec .icofont-eye-blocked {
    position: absolute;
    right: 0;
    top: 16px;
    display: none;
}

.login-screen .main-content .login-sec .icofont-eye-alt::before {
    font-size: 20px;
}

.login-screen .main-content .login-sec .icofont-eye-blocked::before {
    font-size: 20px;
}

.frgt-pass a button.stap-button {
    background: transparent;
    border: 0;
    padding: 0;
    color: #ff724c;
    margin-top: 10px;
}

.frgt-pass a {
    display: block;
    font-family: 'Segoe UI Bold';
}

.submit-btn button.button {
    background: #ff724c;
    border: 0;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: #fff;
    border-radius: 6px;
    font-family: 'Segoe UI Bold';
}

.submit-btn {
    margin-top: 30px;
}

.noaccount {
    margin-top: 20px;
    text-align: center;
}

.noaccount a {
    font-family: 'Segoe UI Bold';
}

header.header .menu-items {
    font-size: 25px;
}

.qr-code {
    display: flex;
    justify-content: flex-end;
}

.qr-code img {
    max-width: 21px;
}

.profile-sec {
    position: relative;
    padding: 20px;
}

.profile-sec .profile {
    background: #fff;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 12px rgb(0 0 0 / 20%);
    align-items: center;
}

.profile-sec .profile .col-md-4 {
    padding-left: 0;
}

.profile-sec .profile .rol-sec {
    width: auto;
    padding: 15px 0;
}

.profile-sec .profile .rol-sec h1 {
    font-size: 30px;
    color: #000;
}

.profile-sec .profile .rol-sec p.rol {
    font-size: 13px;
    font-weight: 600;
    color: #727272;
}

.profile-sec:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 150px;
}

.profile-sec .profile .edit-optn {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 40px;
    background: #ff643a;
    border-radius: 50px;
    padding: 7px;
}

.profile-sec .profile .col-md-4 img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 207px;
    object-fit: cover;
}

.login-screen .main-content p {
    font-size: 16px;
    color: #686868;
}

.main-content .name-hrdr {
    padding: 25px 15px 0;
}

.main-content .name-hrdr h2 {
    color: #000;
}

.button-sec .action {
    margin: 20px 0;
    display: flex;
}

.button-sec .action a.stap-link {
    margin-right: 10px;
    width: auto;
}

.button-sec .action a {
    width: 100%;
}

.button-sec .action a.stap-link button.stap-button {
    background: transparent;
    border: 1px solid #aaa;
    min-width: 154px;
    color: #000;
}

.button-sec .action a.stap-link button.stapp-button:hover {
    color: #fff;
    background: #000;
}

.button-sec .action button.stap-button {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 10px;
    border: 0;
    background: #df4e26;
    color: #fff;
    font-family: 'Segoe UI Bold';
}

.heder-sec {
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 15px;
}

.heder-sec h3.sec-hdng {
    font-size: 20px;
    color: #1a202c;
}

.heder-sec a {
    font-family: 'Segoe UI Bold';
}

.profile-screen p {
    font-size: 17px;
    color: #686868;
}

.social-sec .socials {
    width: 100%
}

.social-sec .socials p.icon {
    padding: 15px;
    border-radius: 5px;
    background: #eee;
    margin-right: 20px;
    float: left;
}

.social-sec .socials p.icon:hover {
    background: #ccc;
}

.shadow-box {
    padding: 20px;
    border: 1px solid #aaa;
    border-radius: 10px;
    text-align: center;
    font-family: 'Segoe UI Bold';
}

.shadow-box p {
    font-family: 'Segoe UI Bold';
    margin-bottom: 0;
}

.contsct-sec .info-box {
    display: flex;
    align-items: center;
    padding: 10px;
}

.contsct-sec .info-box .info-icon {
    width: 20px;
    margin-right: 30px;
    margin-bottom: 0;
    height: 0;
    padding: 0;
}

.contsct-sec .info-box p.contacts {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 12px;
}

.contsct-sec .info-box p.contacts a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Segoe UI Bold';
    color: #333;
}

.contsct-sec .info-box p.contacts a .errow {
    width: 21px;
}

.analytics {
    border-top: 1px solid #e1e1e1;
    margin-top: 40px;
}

.analytics .alltime-items span.hdr {
    color: #999;
}

.analytics .alltime-items {
    padding: 10px 0;
}

.analytics .alltime-items .content {
    display: flex;
    text-align: center;
    font-family: 'Segoe UI Bold';
}

.analytics .alltime-items .content .items {
    margin: 0px 15px 0 0;
    width: 100px;
}

.analytics .alltime-items .content .items h4.velue-cstm {
    color: #1a202c;
    font-size: 30px;
}

.analytics span.all-time {
    font-family: 'Segoe UI Bold';
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.profile-sec .profile .edit-optn.remove {
    left: -8px;
    right: auto;
    text-align: center;
    font-size: 26px;
    color: #ff643a;
    padding: 0;
    background: #fff;
}

.profile-sec .profile .upload-photo {
    position: absolute;
    bottom: -19px;
    left: 50%;
    background: #df4e26;
    border: 3px solid #fff;
    padding: 5px;
    width: 50px;
    border-radius: 50px;
    text-align: center;
    height: 50px;
    line-height: 31px;
    font-size: 23px;
    color: #fff;
    margin-left: -25px;
}

.sidenav .name-rol {
    padding: 0 20px;
}

.nav-sec .menu-list a.menu-link {
    font-size: 17px;
    padding: 5px 0;
    color: #333;
}

.nav-sec .menu-list {
    padding: 20px;
}

.infof-section {
    margin-top: 40px;
}

.infof-section .form .field-sec {
    margin-bottom: 20px;
}

.infof-section .form span.label {
    display: block;
    color: #7c7c7c;
}

.infof-section .form input {
    border: 0;
    border-bottom: 1px solid #aaa;
    width: 100%;
    padding: 5px 0;
    font-size: 18px;
}

.infof-section .form .field-sec select {
    border: 0;
    border-bottom: 1px solid #aaa;
    width: 100%;
    padding: 5px 0;
    font-size: 18px;
}

.infob-section .form .field-hdng {
    display: flex;
    justify-content: space-between;
    color: #909090;
    width: 100%;
}

.infob-section .form .bio-field textarea {
    text-align: left;
    height: 108px;
    border: 0;
    border-bottom: 1px solid #aaa;
    width: 100%;
    padding: 5px 0;
    font-size: 18px;
}

.infob-section {
    margin-top: 30px;
    padding: 0 15px;
}

.bio-field textarea::-webkit-scrollbar {
    width: 6px;
    border-radius: 50px;
}

.bio-field textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.bio-field textarea::-webkit-scrollbar-thumb {
    background: #888;
}

.overlay.show {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}

.nav-sec {
    position: relative;
    z-index: 99;
    height: 100%;
    padding-bottom: 50px;
}

.nav-sec .log-out {
    position: absolute;
    bottom: 0;
}

.nav-sec .log-out a {
    color: #333;
    padding: 0 20px 20px;
}

.sidenav .name-rol p.email {
    color: #aaa;
    font-size: 16px;
}

.sidenav .name-rol h2 {
    font-size: 27px;
}

.acount-stng {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
}

a.closebtn {
    position: absolute;
    z-index: 999;
    background: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 33px;
    font-size: 42px !important;
    border-radius: 50px;
    right: 10px;
    text-decoration: none;
    top: 0;
    padding: 0;
    color: #000;
    box-shadow: 0 0 9px rgba(0, 0, 0, 20%);
}

a.closebtn.hide {
    display: none;
}

.social-edit-sec .form .field-hdng button.button.css-gocinq {
    padding: 0;
    border: 0;
    background: transparent;
    color: #868686;
}

.social-edit-sec .form input {
    border: 0;
    border-bottom: 1px solid #aaa;
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    border-radius: 0;
}

.social-edit-sec .form select {
    border: 0;
    border-bottom: 1px solid #aaa;
    padding: 6px 0;
    font-size: 16px;
    width: 154px;
}

.video-sec .social-edit-sec .form select {
    border: 0;
    border-bottom: 1px solid #aaa;
    padding: 6px 0;
    font-size: 16px;
    width: 100%;
}

.social-edit-sec .form .input {
    margin-left: 15px;
    width: 100%;
}

.social-edit-sec .add-new {
    margin-top: 20px;
    color: #df4e26;
    font-weight: 600;
    cursor: pointer;
}

.social-edit-sec .field {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.infof-section.add-link-sec {
    padding: 0 15px;
}

.uload-sec {
    display: flex;
    align-items: center;
}

.uload-sec .form {
    width: 70px;
    height: 70px;
    background: #e0e0e0;
    border-radius: 5px;
    position: relative;
    text-align: center;
    line-height: 64px;
    margin-right: 20px;
}

.uload-sec .form input {
    border: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
}

.uload-sec p.sec-hdng {
    margin: 0;
    color: #df4e26;
}

.video-sec .social-edit-sec .field {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.infob-section.social-edit-sec .row {
    margin-bottom: 20px;
}

.infob-section.social-edit-sec .row h4 {
    margin-top: 30px;
}

.form.pdng .field span.input {
    margin: 0;
}

.social-edit-sec .form.wdth .field {
    width: 100%;
    margin-bottom: 20px;
}

.social-edit-sec .form.wdth .field span.select {
    width: 100%;
}

.social-edit-sec .form.wdth .field select {
    width: 100%;
}

.social-edit-sec .form.wdth span.input {
    margin: 0;
}

.custom_link_img.edit_link .field .btn_action .select.custom_link_p {
    width: 100%;
    padding-top: 0;
}

.custom_link_img.edit_link .field .btn_action {
    width: 100%;
}

.custom_link_img.edit_link .field {
    display: flex;
}

.link_action a {
    padding-right: 15px;
}

.link_action {
    margin-left: 20px;
}

.row.custom_link_img.edit_link {
    margin-top: 30px;
}

.head_err_msg {
    display: none;
}

.alert.alert-danger.is_error ul li {
    list-style: none;
}

.mySidenav {
    width: 350px;
}

.croppedimg img {
    margin-left: 20px;
    width: 100px;
    height: 100px;
}

.croper {
    margin-top: 20px;
}

.alert ul {
    margin: 0 !important;
}

.video-sec iframe {
    width: 100%;
    height: 292px;
}

.uload-sec img {
    width: 75px;
    float: right;
    margin-left: 30px;
}

.edit {
    text-align: right;
    float: left;
    width: 15%;
}

.heder-sec h3.sec-hdng {
    width: 70%;
}

.all-time {
    width: 30%;
    text-align: right;
}

.menu.col-sm-4 {
    width: 33%;
}

.accordion-items {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 30px 0;
    padding: 20px;
    border-radius: 10px;
    font-size: 19px;
    font-weight: bold;
}

.accordion-text {
    width: 85%;
    float: left;
}

.right-icon {
    text-align: right;
    cursor: pointer;
}

.accordion-content {
    margin: 20px auto;
    width: 60%;
}

.my-qr-code {
    border: 8px solid #000;
    width: 100%;
    border-radius: 10px;
    background: #000;
}

.my-qr-code img {
    padding: 10px;
    background: #fff;
    width: 100%;
}

.my-qr-code p {
    text-align: center;
    padding: 7px;
    margin: 0;
    color: #fff;
}

.accordion-items:hover,
.accordion-items:focus {
    background: #f4f4f4;
}

.icofont-navigation-menu.show,
.log-out {
    cursor: pointer;
}

.header figure img {
    width: 100px;
}

.phone_div {
    padding: 0 0 0 45px !important;
}

.custom_link_img {
    margin-bottom: 21px;
}

.custom_link_p {
    margin-bottom: 0rem;
}

.welcome-screen .content h3 {
    font-size: 38px;
    color: #fff;
}

.welcome-screen .content p {
    font-size: 20px;
    color: #d0d0d0;
}

.welcome-screen .content .button .chakra-button {
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: 5px;
    background: #df4e26;
    color: #fff;
    font-size: 18px;
}

.welcome-screen .content {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 30px;
    background-color: #111;
    background-image: url(https://dev.smarttap.world/wp-content/uploads/2022/03/welcome.png);
    background-size: 100%;
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
}

.welcome-screen .button {
    margin-top: auto;
}

#profile2 .profile-hd .button a {
    display: table;
    margin-left: auto;
    padding: 5px 18px;
    border: 1px solid #8e8e8e;
    color: #fff;
    border-radius: 5px;
}

#profile2 .profile-hd img {
    max-width: 90px;
}

#profile2 .profile-hd {
    margin-bottom: 15px;
}

#profile2 .profile {
    align-items: center;
    margin: 0;
}

#profile2 .profile .profile-pic {
    padding-left: 0;
}

#profile2 .profile .profile-pic img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-height: 205px;
    object-fit: cover;
    width: 100%;
}

#profile2 .profile h1 {
    font-size: 35px;
}

#profile2 .profile-hd .col-md-6 {
    padding: 0;
}

#profile2 .profile p.rol {
    font-size: 22px;
}

#profile2 .button-sec .exchange,
.exchange {
    background: #000;
}

#profile2 .button-sec a.stap-link {
    width: 45%;
}

#profile2 .button-sec a:last-child {
    width: 55%;
}

.errow img {
    width: 40px;
    float: right;
    margin-top: 10px;
}

.info-icon {
    width: 67px;
    height: 64px;
    border-radius: 6px;
    background: #eee;
    padding: 7px;
    display: flex;
    align-items: center;
    float: left;
}

.info-icon img {
    width: 100%;
    border-radius: 10px;
}

.select {
    width: 60%;
    float: left;
    padding: 15px 20px 0px;
}

.contsct-sec .info-icon img {
    height: auto;
}

.profile-sec .profile .upload-photo .icofont-camera {
    display: block;
    position: relative;
    font-size: 33px;
}

.profile-sec .profile .upload-photo .icofont-camera input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.profile-screen .header .row {
    align-items: center;
}

.infob-section.social-edit-sec .select {
    padding: 0;
}

.select select {
    background: #fff;
}

.custom-links .text2 {
    color: #ff724c;
    font-weight: bold;
    cursor: pointer;
}

.loader img {
    width: 300px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    position: fixed;
    height: 100%;
    z-index: 9;
    align-items: center;
    background: #fff;
    width: 100%;
    text-align: center;
}

.button-sec .modal-content .field input.form-control {
    border: 0;
    border-bottom: 1px solid #c6c6c6;
    border-radius: 0;
    padding: 11px 0;
    height: auto;
    margin-bottom: 15px;
}

.button-sec .modal-content .field {
    margin-top: 20px;
}

.button-sec .modal-content .field p {
    font-size: 16px;
}

.button-sec .modal-content .modal-footer button.btn.btn-default {
    width: 100%;
    background: #000;
    color: #fff;
    height: auto;
    padding: 10px;
    border-radius: 6px;
}

.button-sec .modal-content .modal-footer {
    margin-bottom: 20px;
}

.modal-header {
    padding-top: 35px;
}

.showmyModal {
    top: 60px;
    display: block;
}

.fade:not(.show) {
    opacity: 1;
}

.custom-links {
    padding: 0px 20px;
}

.row.custom_link_img.edit_link .text {
    cursor: pointer;
}

.col-xs-12.design {
    width: 100%;
    float: left;
    margin: 30px 0;
}

.color-design ul {
    margin: 0;
    padding: 0;
}

.upper-color {
    position: absolute;
    inset: 0px;
    clip-path: polygon(100% 0px, 0px 0px, 0px 100%);
}

.bottem-color {
    position: absolute;
    inset: 0px;
    clip-path: polygon(100% 0px, 100% 100%, 0px 100%)
}

.color-design ul li {
    position: relative;
    width: 55px;
    height: 55px;
    box-shadow: var(--chakra-shadows-brand-sm);
    border-radius: var(--chakra-radii-lg);
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
    filter: opacity(1);
    user-select: none;
    list-style: none;
    float: left;
    margin: 0 3px 10px;
    border: 1px solid #999;
}

.box1 h1,
.box3 h1,
.box4 h1,
.box5 h1 {
    color: #fff !important;
}

.box1 .rol,
.box3 .rol,
.box4 .rol,
.box5 .rol {
    color: rgb(227, 227, 227) !important;
}

.box1 {
    background: rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(255, 92, 36) 10%, rgb(242, 10, 36) 70%, rgb(199, 18, 101) 100%) repeat scroll center center / cover !important;
}

.box11::before {
    background: rgb(113, 13, 107) none repeat scroll 0% 0% / cover !important;
}

.box2 {
    background: rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(247, 211, 242) 0%, rgb(219, 248, 254) 30%, rgb(198, 223, 251) 100%) repeat scroll center center / cover !important;
}

.box22::before {
    background: rgb(175, 181, 188) none repeat scroll 0% 0% / cover !important;
}

.box3 {
    background: rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(41, 109, 54) 0%, rgb(62, 176, 68) 100%) repeat scroll center center / cover !important;
}

.box33::before {
    background: rgb(50, 69, 57) none repeat scroll 0% 0% / cover !important;
}

.box4 {
    background: rgb(1, 1, 1) none repeat scroll center center / cover !important;
}

.box44::before {
    background: rgb(255, 100, 58) none repeat scroll 0% 0% / cover !important;
}

.box5 {
    background: rgb(35, 81, 164) none repeat scroll center center / cover !important;
}

.box55::before {
    background: rgb(16, 52, 116) none repeat scroll 0% 0% / cover !important;
}

.box6 {
    background: rgb(255, 255, 255) none repeat scroll center center / cover !important;
}

.box66::before {
    background: rgb(0, 0, 0) none repeat scroll 0% 0% / cover !important;
}

.box7 {
    background: rgba(0, 0, 0, 0) linear-gradient(135deg, rgb(217, 220, 202) 0%, rgb(145, 203, 202) 100%) repeat scroll center center / cover !important;
}

.box77::before {
    background: rgb(147, 204, 202) none repeat scroll 0% 0% / cover !important;
}

.color-design .active {
    border: 4px solid #df4e26 !important;
}

.arrow-down:before {
    content: "\ea99";
    font-weight: 900;
    float: right;
}

.arrow-down:before {
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

#faq .card .collapse {
    border-bottom: 1px solid #aaa;
}

.card .btn.btn-header-link.active {
    color: #df4e26 !important;
    font-weight: bold !important;
}

.profile-list-sec .info-section .field {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #aaa;
    padding-bottom: 8px;
    margin-bottom: 30px;
}

.profile-list-sec .info-section .field .icofont-search-1 {
    font-size: 25px;
    margin-right: 10px;
}

.profile-list-sec .info-section input[type="text"] {
    border: 0;
    width: 100%;
}

.profile-list-sec .card p.alfbtc {
    background: #f0f0f0;
    padding: 5px 10px;
    margin: 0;
}

.profile-list-sec .card .card-header span.arrow-down {
    position: absolute;
    right: 10px;
}

.profile-list-sec .card .card-header a.btn.btn-header-link {
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #000;
}

.profile-list-sec .card .card-header {
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 2px solid #aaa;
}

.profile-list-sec .card {
    border: 0;
}

.btn:focus,
.btn.focus {
    box-shadow: none;
}

.profile-list-sec {
    padding: 0 15px;
    margin-top: 20px;
}

.collapse.in {
    display: block !important;
}

.alfbtc.not {
    display: none;
}

.contsct-sec .button-sec {
    margin: 0 auto !important;
    width: 50%;
}

.right-icon .icofont-link,
.right-icon .icofont-qr-code,
.right-icon .icofont-computer {
    color: #ff724c;
}

.accordion-content2 p {
    font-weight: normal;
    font-size: 14px;
}

.profile-sec .profile .rol-sec {
    width: 63%;
}

.col-md-6.profile-pic img {
    height: auto !important;
    min-height: 205 !important;
}

.contsct-sec .info-box p.contacts {
    overflow: auto;
}

.my-backgroud img {
    width: 33%;
    padding: 4px;
    cursor: pointer;
    border-radius: 10px;
}

.my-backgroud img:target {
    border: 3px solid #ff724c;
    margin: 0;
}

#my-node {
    background-image: url('https://dev.smarttap.world/wp-content/uploads/2022/02/zoom1.png');
}

@media screen and (max-width: 480px) {
    #profile2 .live_btn {
        top: -25px !important;
    }
    .delete-profile {
        right: -25px;
    }
    .profile-sec .profile .rol-sec {
        width: 50%;
    }

    .col-md-6.profile-pic img {
        height: 205px !important;
    }

    .profile-sec .profile .col-md-4 img {
        height: 233px;
        object-fit: cover;
    }

    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }

    .custom_link_img.edit_link .field .btn_action {
        width: 80%;
    }

    .login-screen .header {
        padding: 0;
    }

    header.header {
        margin-top: 20px;
        padding: 0 15px;
    }

    .profile-screen .container {
        padding: 0;
    }

    .profile-sec .profile {
        flex-wrap: nowrap;
    }

    .profile-sec .profile .col-md-4 {
        padding-left: 0;
        width: 50%;
    }

    .button-sec .action a.stap-link button.stap-button {
        min-width: 130px;
    }

    #profile2 .profile .profile-pic {
        padding-left: 0;
        width: 50%;
    }

    #profile2 .profile-hd .col-md-6 {
        padding: 0;
        width: 50%;
    }

    .header.public_hed {
        margin: 0;
    }

    #profile2 .profile-hd {
        margin-bottom: 15px;
        flex-wrap: nowrap;
    }

    .modal-header {
        padding-top: 60px;
    }

    #myModal {
        width: 375px;
    }

    #profile2 .profile .profile-pic {
        width: 46%;
    }

    #profile2 .profile h1 {
        font-size: 25px;
    }

    #profile2 .profile p.rol {
        font-size: 13px;
    }

    .custom-links {
        padding: 0px 20px;
    }
}

.button-sec .action a.stap-link button.stap-button:hover {
    background: #000;
    color: #fff;
}

.info-icon.icofont-company,
.info-icon.icofont-meetup {
    font-size: 27px;
}

.export-connection.icofont-download {
    float: right;
    border: 1px solid;
    padding: 5px 10px;
}

.info-box.comp-wemet p {
    font-weight: bold;
    color: #000;
}

.link-sec,
.video-sec,
.contsct-sec,
.analytics {
    width: 100%;
    float: left;
}

.form-control.phone_n {
    margin: 40px;
    width: 90%;
}

.fis-righta {
    width: 100%;
    float: left;
    display: flex;
    margin-top: 15px;
}

.team-member {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    margin-top: 55px;
}

.team-member .item {
    flex: 0 0 50%;
    margin: 0 8px 0 0;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
}

.fis-righta img {
    position: absolute;
    right: 10px;
}

.fis-righta h3 {
    font-size: 20px;
    color: #1a202c;
}

.team-member .item img {
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 175px;
    object-fit: cover;
}

.sec-righta {
    margin: 100px auto;
}

.team-member p {
    font-size: .75rem;
    line-height: 1rem;
    margin-top: .125rem;
    padding: 0 8px;
}

.team-member h5 {
    font-size: 1rem;
    padding: 8px 8px 0px;
}

.info-icon.phone-icons img {
    border-radius: initial;
}

button:disabled {
    background: #bdbdbd !important;
    color: #e6e6e6 !important;
}
.main-content.no-account {
    text-align: center;
    margin-top: 50px;
}
.video-sec iframe {
    height: 230px;
    border-radius: 5px;
}
.row.custom_link_img .select a {
    color: #000 !important;
}

#model-custom .model-content {
    position: fixed;
    bottom: 0;
    background: #fff;
    box-shadow: 0 -7px 60pxrgba(0,0,0,.08);
    border-radius: 24px 24px 0 0;
    width: 100%;
    padding: 0;
    color: #fff;
    max-width: 550px;
}
.closs_class {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(64px);
    backdrop-filter: blur(64px);
    opacity: .3;
}
.profile-list ul li {
    list-style: none;
    display: flex;
    align-items: center;
}
.profile-list ul {
    margin: 0;
    padding: 0;
}
.profile-list ul li img {
    width: 45px;
    height: 45px;
}
.live_btn.activate a {
    background: #b5b5b5;
    text-transform: capitalize;
}
.model-hdr h3 {
    color: #000;
    font-size: 20px;
}
.p-name {
    padding-left: 15px;
}
.p-name h4 {
    color: #000;
    font-size: 18px;
}
.profile-screen .p-name p {
    font-size: 14px;
    color: #686868;
    padding-left: 10px;
    margin: 0 !important;
}
.live_btn {
    position: absolute;
    right: 5px;
}
.profile-list li {
    position: relative;
    cursor: pointer;
    padding-bottom: 10px;
}
.live_btn a {
    text-transform: uppercase;
    background: #3eb244;
    color: #fff;
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 12px;
}
.all-profile .profile .profile-pic img {
    min-height: 205px !important;
}
.popup-custom {
    padding: 20px 15px;
}
.model-hdr p {
    font-size: 12px;
    color: #686868;
}
.model-content .close {
    border-radius: 50%;
    padding: 3px 8px 8px;
    font-size: 20px;
    background: #16191d;
    color: #fff;
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}   
.all-profile {
    padding: 0 !important;
}
.all-profile .profile {
    margin-bottom: 20px !important;
    cursor: pointer;
}
.all-profile.profile-sec::before {
    background: #fff;
}
#profile2 .live_btn {
    position: absolute;
    right: 10px;
    top: -15px;
}
.profile-list li:focus,.profile-list li:hover,.profile-list li:active {
    background: #ececec;
}
.all-profile .profile:focus,.all-profile .profile:hover,.all-profile .profile:active {
    border: 2px solid #090909;
}
.change-profile select {
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #cacaca;
}
.change-profile p {
    font-size: 12px;
}
.logo .switch-profile {
    text-align: center;
    cursor: pointer;
}
.change-profile labal {
    font-weight: bold;
    margin-bottom: 5px;
    width: 100%;
    float: left;
}
.menu.col-md-8 {
    width: 60%;
    float: left;
}
.menu.col-md-2 {
    width: 20%;
    float: left;
}
.profile-logo {
    width: 50%;
    margin: 0 auto;
    display: flex;
}

input:focus, select:focus, textarea:focus {
      border-bottom: 1px solid #df4e26 !important;
}
.delete-profile {
    width: 30px;
    position: absolute;
    right: -15px;
    background: #7b7b7b;
    border-radius: 15px;
    text-align: center;
    height: 30px;
    top: 35%;
    cursor: pointer;
}
.delete-profile img {
    width: 17px;
}